.Form-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 95vh;
}

.Form-container {
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    min-width: 500px!important;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 450px) {
        min-width: 90%;
    }

    .Form-button {
        transition: all 0.5s ease-in-out;


    }

    .Form-button:hover {
        transform: scale(1.2);
    }
}

.Goal-container{
    max-width: 45%;
    display: flex;
    align-items: center;
    flex-direction: column;

}