.login-form {
    min-width: 300px;
    max-width: 600px;
    width: 25vw;
    // background-color: #14141b;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    // opacity: 0.85;
    box-shadow: 0px 5px 10px 0px rgba(5, 5, 5, 0.2);
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);

    h1 {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 400;
        margin-bottom: 40px;
    }

    .tools {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 80%;
        font-size: small;

        .forgot-password {
            color: #f00;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .remember-me{
            flex-direction: row;
            display: flex;
            align-items: center;
        }
    }

    .link {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        a {
            text-decoration: underline;
            text-decoration-color: red;
            text-underline-offset: 2px;
        }

        p {
            font-size: small;
            color: #eee;
            transition: all 0.3s ease;
        }

        p:hover {
            cursor: pointer;
            transform: scale(1.05);
            color: #f00;
        }
    }
}