html {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white!important;
  height: 100%;
  width: 100%;
  background-color: #04040D;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-x: hidden;
}

#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: max-content; */
  width: 100%;
  flex: 1 1 auto;
}

.Toastify {
  position: absolute;
}

.Toastify__toast {
  box-shadow: 0px 5px 10px 0px rgba(5, 5, 5, 0.2);
  background-color: rgba(0, 0, 0, 0.2) !important;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

}

.blurry-bg {
  box-shadow: 0px 5px 10px 0px rgba(5, 5, 5, 0.25);
  background-color: rgba(0, 0, 0, 0.25) !important;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.glow {
  animation: textGlow 3000ms linear infinite 2000ms;
}

@keyframes textGlow {
  40% {
    text-shadow: 0 0 50px #fff;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #080818;
  border-radius: 15px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #f00;
}

*::-webkit-scrollbar-track {
  background-color: #04040D;
}