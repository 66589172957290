.custom-button {
    position: relative;
    display: block;
    width: 80%;
    height: 40px;
    padding: 0 10px;
    border-radius: 40px;
    background-color: #f00;
    font-size: 14px;
    line-height: 40px;
    color: #333;
    transition: all 0.3s ease;
    border: none;
    color: white;
}

.custom-button:hover {
    cursor: pointer;
    transform: scale(1.05);
}