.Statistics-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .card {
        max-width: max-content;

        @media screen and (max-width: 1200px) {
            width: 90%;
            max-width: 90%;
            text-align: center;
        }
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    .Section1 {
        flex: 1;
        display: grid;
        grid-template-rows: 1fr 0.5fr 1fr;
        padding: 20px;

        @media screen and (max-width: 1200px) {
            order: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }
    }

    .Section2 {
        flex: 1.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        #info {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 5px
        }

        @media screen and (max-width: 1200px) {
            #info {
                display: none;
            }
        }
    }

    .Section3 {
        flex: 1;
        display: grid;
        grid-template-rows: 1fr 0.5fr 1fr;
        padding: 20px;
        justify-items: end;

        @media screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
                justify-content: space-around;
            align-items: center;
        }
    }

    .predictions-icons {
        padding: 10px;
        opacity: 0.6;
    }
}