.footer {
    text-align: center;
    width: 100%;
    height: 90px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .floating-arrow {
        position: sticky;
        transition: all 0.5s ease-in-out;

        :hover {
            transform: scale(1.5);
            cursor: pointer;
        }
    }

    .arrow-icon {
        transition: all 0.5s ease-in-out;

        :hover {
            transform: scale(1);
        }
    }
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        .nav-container {
            flex: 1;
            li {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        p {
            flex: 1;
        }
    }
}
.useful-links{
    p{
        width: max-content;
    }
}

