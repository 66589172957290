.home-container {
    width: 100%;


    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 70px;

        h1 {
            font-size: 5vmax;
            text-align: center;
            color: red;
        }

        h2 {
            font-size: 4.5vmax;
            text-align: center;
        }

        .floating-arrow {
            position: sticky;
            animation: floating-arrow-animation 1.5s infinite;
            transition: all 0.5s ease-in-out;

            @keyframes floating-arrow-animation {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(15px);
                }

                100% {
                    transform: translateY(0);
                }
            }

            :hover {
                transform: scale(1.5);
                cursor: pointer;
            }
        }

        .arrow-icon {
            transition: all 0.5s ease-in-out;

            :hover {
                transform: scale(1);
            }
        }

    }

    .video-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        position: relative;
        animation: fade-in 1s ease-in-out;
        transition: all 0.5s ease-in-out;
        margin-bottom: 30px;
        font-size: 70px;

        @keyframes fade-in {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        video {
            width: 70%;
            object-fit: cover;
            object-position: center;
            background-color: #000;
            border-radius: 12px;
            opacity: 0.9;
        }

        .floating-arrow {
            position: sticky;
            animation: floating-arrow-animation 1.5s infinite;
            transition: all 0.5s ease-in-out;

            @keyframes floating-arrow-animation {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(15px);
                }

                100% {
                    transform: translateY(0);
                }
            }

            :hover {
                transform: scale(1.5);
                cursor: pointer;
            }
        }

        .arrow-icon {
            transition: all 0.5s ease-in-out;

            :hover {
                transform: scale(1);
            }
        }
    }

    .content {
        margin-left: 5%;
        margin-right: 5%;
        padding: 80px 10px 80px 10px;
        border-radius: 15px;

        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 30px;

        .ai-card {
            max-width: 450px;
            height: 100%;
            padding: 40px 20px 40px 20px;
            margin: 20px;
            background-color: rgba(30, 30, 30, 0.3);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease-in-out;
            text-align: center;
        }

        .ai-card.active:hover {
            cursor: pointer;
        }

        .trynow-btn {
            animation: floating-arrow-animation 1.5s infinite;
            transition: all 0.5s ease-in-out;

            @keyframes floating-arrow-animation {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(10px);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }

        p {
            font-size: x-large;
        }

        @media screen and (max-width: 900px) {
            flex-direction: column;
            margin: 0;

            p {
                font-size: large;
                width: 100%;
            }
        }
    }
}