.title {
    cursor: default;
    background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, #f00, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: #f00;
    // color: transparent;
    animation: glow 2500ms linear infinite 2000ms;

    @keyframes glow {
        40% {
            text-shadow: 0 0 40px #f00;
        }
    }
}

.navbar {
    box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.3);
    // background-color: rgba(255, 255, 255, 0.1);
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    z-index: 3;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.brand-title {
    display: flex;
    column-gap: 10px;
    margin-left: 20px;
    align-items: center;
    :hover{
        cursor: pointer;
    }
}


.navbar-list {
    display: inline-block;
    padding: 0;
    // text-transform: uppercase;
}

.navbar-links ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li a {
    font-size: 22px;
    display: block;
    text-decoration: none;
    color: white;
    padding: 1rem;
}

.cool-link {
    cursor: pointer;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
    transition: width .3s;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    min-width: 150px;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
    background: linear-gradient(rgb(30, 10, 10), #0000);
    border-radius: 5px;
    transition: all 0.3s ease;
    opacity: 0;
}

/* Show the dropdown menu on hover */
.dropdown {
    display: flex;
    align-items: center;
}

.dropdown:hover .dropdown-content {
    display: flex;
    opacity: 1;
}

.dropdown:hover #Avatar {
    box-shadow: 0 0 1pt 1pt white;
    transition: all 0.3s ease;
}

.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.3);

}

.autocomplete-items {
    position: absolute;
    color: black;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
    background: linear-gradient(rgb(30, 10, 10), #0000);
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    z-index: 3;
    color: white;
    border-radius: 10px;
}

.autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
    color: black;
}


.drawer {
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2) !important;
    background-color: #04040D !important;
    opacity: 0.99;
    -webkit-backdrop-filter: blur(15px) !important;
    backdrop-filter: blur(15px) !important;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.drawer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    // padding-top: 40px;
}

.seperator {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 10px 0;
}


.drawer-header {
    font-weight: 600;
    animation: glow2 2500ms linear infinite 2000ms;

    @keyframes glow2 {
        40% {
            text-shadow: 0 0 40px #fff;
        }
    }
}

.EZDrawer__overlay{
    height: 100vh!important;
}