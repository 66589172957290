.Loading-Page {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    background-color: #04040D;

    img {
        animation: glow 2s infinite alternate;
    }

    @keyframes glow {
        0% {
            -webkit-filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
            filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
        }

        100% {
            -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
        }
    }
}